import React from "react"
import Navbar from "../components/Navbar"
import About from "../components/About"
import Portfolio from "../components/Portfolio"
import Experience from "../components/Experience"
import Contact from "../components/Contact"
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { Helmet } from "react-helmet"

const Home = () => {
  return (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>kyle liang</title>
    </Helmet>
    <Navbar ></Navbar>
    <About ></About>
    <Portfolio></Portfolio>
    <Experience></Experience>
    <Contact></Contact>
  </div>
  )
}

export default Home
