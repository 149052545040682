import React from "react"
import styled from "styled-components"
import Card from 'react-bootstrap/Card'

const CCard = styled(Card)`
	height: 30vh;
	//width: 40vw;
	margin-top: 5vh;
	
	background-color: #052F5F;

	@media (max-width: 768px) {
		//width: 80vw;
		height: 30vh;
		
	}
`

const PortfolioItem = (props) => {
    return (
			// <CContainer>
				// <Header> {props.projName} </Header>
				// <LinkItem href={props.githubLink} target="_blank" rel="noreferrer"> View on Github </LinkItem>
				// {props.hasDemo ? 
				// 	<LinkItem href={props.projLink} target="_blank" rel="noreferrer"> Check it Out </LinkItem>
				// 	:
				// 	<span></span>
				// }
				

				<CCard >
					<Card.Body>
						<Card.Title style={{color: "#F1A208"}}> {props.projName} </Card.Title>
						<Card.Text style={{color: "#D5C67A"}}>
							{props.projDesc}
						</Card.Text>
						{props.hasDemo ? 
						<Card.Link style={{color: "#D5C6AF"}} href={props.projLink} target="_blank" rel="noreferrer">Check it Out</Card.Link>
						:
						<span></span>
						}
						<Card.Link style={{color: "#D5C6AF"}} href={props.githubLink} target="_blank" rel="noreferrer">View on Github</Card.Link>
					</Card.Body>
				</CCard>
			// </CContainer>
    )
}

export default PortfolioItem