// NavbarLinks.js

import React from "react"
import styled from "styled-components"
import {Link} from 'react-scroll'

const NavItem = styled.div`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
	
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #F1A208;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: #F1A208;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 0;
  }
`
const NavbarLinks = () => {
  return (
    <>
      <NavItem ><Link  to="about" spy={true} smooth={true}>About</Link></NavItem>
      <NavItem ><Link  to="portfolio" spy={true} smooth={true}>Projects</Link></NavItem>
      <NavItem ><Link  to="experience" spy={true} smooth={true}>Experience</Link></NavItem>
      <NavItem ><Link  to="contact" spy={true} smooth={true}>Contact</Link></NavItem>
    </>
  )
}

export default NavbarLinks