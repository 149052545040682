import React, {useState} from "react"
import styled from "styled-components"
//import TitleContent from "./TitleContent"
import Typist from 'react-typist'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'react-typist/dist/Typist.css'
import './About.css'
import Media from 'react-media';

const CContainer = styled(Container)`
	height: 90vh;
	background-color: #005377;
	padding-top: 18vh;
	color: #D5C67A;

	@media (max-width: 768px) {
		padding-top: 10vh;
	}
`

const styles = {
	spacing: {
		marginTop: "2.5vh"
	}
};

const About = () => {
	const [typing, setTyping] = useState(true)

	const handleTypingEnd = () => {
		setTyping(false);
		setTimeout(setTyping(true), 5000);

	}

	const msgs = ["Kyle Liang", "Developer", "College Student", "Game Enthusiast"]

	const data = useStaticQuery(graphql`
		query {
			pfpmobile: file(relativePath: { eq: "profilepic.png" }) {
				childImageSharp {
					fixed(height: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			pfpfull: file(relativePath: { eq: "profilepic.png" }) {
				childImageSharp {
					fixed(height: 300) {
						...GatsbyImageSharpFixed
					}
				}
			}
			github: file(relativePath: { eq: "GitHub_Logo_White.png" }) {
				childImageSharp {
					fixed(height: 45) {
						...GatsbyImageSharpFixed
					}
				}
			}
			linkedin: file(relativePath: { eq: "LI-Logo.png" }) {
				childImageSharp {
					fixed(height: 45) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	return (
		<CContainer fluid id="about">
			<Row className="justify-content-center">
				<Col xs="auto" md="auto" className="justify-content-center">
				<Media queries={{ small: { maxWidth: 768 } }}>
          {matches =>
            matches.small ? (
              <Img
								fixed={data.pfpmobile.childImageSharp.fixed}
								alt="Me"
							/> 
            ) : (
              <Img
								fixed={data.pfpfull.childImageSharp.fixed}
								alt="Me"
							/> 
            )
          }
        </Media>
					{/* {window.matchMedia( "(max-width: 768px)" ).matches ?
						<Img
							fixed={data.pfpmobile.childImageSharp.fixed}
							alt="Me"
						/> 
						:
						<Img
							fixed={data.pfpfull.childImageSharp.fixed}
							alt="Me"
						/> 
					} */}
				</Col>
			</Row>
			<Row>
				<Col className="text-center">
					{typing ?
						<Typist onTypingDone={handleTypingEnd}>
							{msgs.map((msg) => (
								<span key={msg}>
									{msg}
									<Typist.Backspace
										count={msg.length}
										delay={5000}
									/>
								</span>
							))}
						</Typist>
						:
						''
					}
				</Col>
			</Row>
			<Row>
				<Col className="text-center">
						<span> Atlanta-based college student who writes code. </span>
				</Col>
			</Row>
			<Row>
				<Col className="text-center">
						<span> Born in Hong Kong, raised in Shanghai. Fluent in English and Mandarin. </span>
				</Col>
			</Row>

			
			<Row xs={1} md={2}>
				<Col className="text-center" style={styles.spacing}>
					<a href="https://github.com/crown523" target="_blank" rel="noreferrer"> 
						<Img
							fixed={data.github.childImageSharp.fixed}
							alt="Github"
						/> 
					</a>
				</Col>
				<Col className="text-center" style={styles.spacing}>
					<a href="https://www.linkedin.com/in/kyle-liang-18a882179/" target="_blank" rel="noreferrer"> 
						<Img
							fixed={data.linkedin.childImageSharp.fixed}
							alt="LinkedIn"
						/> 
					</a>
				</Col>
			</Row>
		</CContainer>
		// </Container>
	)
}

export default About
