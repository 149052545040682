import React from "react"
import styled from "styled-components"
import CustForm from "./CustForm"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Header = styled.h1`
	height: 10vh;
	
`
const CContainer = styled(Container)`
	height: 50vh;
	background-color: #005377;
	color: #F1A208;
`

const Contact = () => {
    return (
		<CContainer fluid id="contact"> 
			<Row>
				<Col className="text-center">
					<Header> contact </Header>
				</Col>
			</Row>
			<Row>
				<Col>
					<CustForm></CustForm>	
				</Col>
			</Row>
			
			
		</CContainer>
		)
}

export default Contact