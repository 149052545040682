import React from "react"
import styled from "styled-components"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
import Media from 'react-media';
import resume from '../data/kliang_resume.pdf'

const Header = styled.h1`
	height: 10vh;
`

const CContainer = styled(Container)`
	height: 50vh;
	background-color: #005377;
	padding-top: 14vh;
	color: #F1A208;

	@media (max-width: 768px) {
		height: 90vh;
		padding-top:10vh;
  }
`

const styles = {
	spacing: {
		marginTop: "8vh"
	}
}

const Experience = () => {
    return (
			<CContainer fluid id="experience"> 
				<Row>
					<Col className="text-center">
						<Header> skills & exp. </Header>
					</Col>
				</Row>

				<Media queries={{ small: { maxWidth: 768 } }}>
          {matches =>
            matches.small ? (
              <Row className="text-center justify-content-center" >
								<Col xs="auto">
									<ListGroup>
										<ListGroup.Item className="bg-dark text-white">C++ <Badge pill variant="primary">Expert</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">Java <Badge pill variant="primary">Expert</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">React.js <Badge pill variant="info">Proficient</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">Python <Badge pill variant="info">Proficient</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">C# <Badge pill variant="secondary">Learning</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">Unity <Badge pill variant="secondary">Learning</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">Node.js <Badge pill variant="secondary">Learning</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white" action >
										<a href={resume} target="_blank" rel="noreferrer"> download my resume </a>
										</ListGroup.Item>
									</ListGroup>
								</Col>
							</Row>
            ) : (
              <Row className="text-center justify-content-center">
								<Col xs="auto">
									<ListGroup>
										<ListGroup.Item className="bg-dark text-white">C++ <Badge pill variant="primary">Expert</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">Java <Badge pill variant="primary">Expert</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">React.js <Badge pill variant="info">Proficient</Badge>{' '}</ListGroup.Item>
									</ListGroup>
								</Col>
								<Col xs="auto">
									<ListGroup>
										<ListGroup.Item className="bg-dark text-white">Python <Badge pill variant="info">Proficient</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">C# <Badge pill variant="secondary">Learning</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white">Unity <Badge pill variant="secondary">Learning</Badge>{' '}</ListGroup.Item>
									</ListGroup>
								</Col>
								<Col xs="auto">
									<ListGroup>
										<ListGroup.Item className="bg-dark text-white">Node.js <Badge pill variant="secondary">Learning</Badge>{' '}</ListGroup.Item>
										<ListGroup.Item className="bg-dark text-white" action >
										<a href={resume} target="_blank" rel="noreferrer"> download my resume </a>
										</ListGroup.Item>
									</ListGroup>
								</Col>
							</Row>
            )
          }
        </Media>
			</CContainer>
		)
}

export default Experience