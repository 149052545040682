import emailjs from 'emailjs-com';
import React, {useState} from "react";
import {user_id} from "./api.json";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

const styles = {
	spacing: {
		marginTop: "5vh"
	}
};

const CustForm = () => {
		const [message, setMessage] = useState("");
		const [name, setName] = useState("");
		const [email, setEmail] = useState("");
		const [formSubmitted, setFormSubmitted] = useState(false);
		const [submitSuccess, setSubmitSuccess] = useState(false);

		const handleChange = event => {
			setMessage(event.target.value);
		}

		const handleName = event => {
			setName(event.target.value);
		}

		const handleEmail = event => {
			setEmail(event.target.value);
		}

		const resetForm = () => {
			setMessage("");
			setName("");
			setEmail("");
			setFormSubmitted(false);
			setSubmitSuccess(false);
		}

		const handleSubmit = event => {
			event.preventDefault();
			console.log(user_id);
			sendReq({
				from_name: name, 
				message: message, 
				reply_to: email
			});
			setFormSubmitted(true);
		}

		const sendReq = ({from_name, message, reply_to}) => {
			emailjs.send("service_s8c4pos","template_t408o4j", {from_name, message, reply_to}, user_id)
			.then((response) => {
				console.log('SUCCESS!', response.status, response.text);
				if (response.status === 200) {
					setSubmitSuccess(true);
				}
			}, (err) => {
				console.log('FAILED...', err);
				alert("Sorry, something went wrong and the email didn't send correctly. Please try again!")
			});
		}

    return (
			<div>
				{formSubmitted && submitSuccess ? 
					<Alert variant="success">
						<Alert.Heading>Email sent successfully</Alert.Heading>
						<p>
							I will be in touch as soon as I am able! If you think you made a mistake or would like to send another email, you can
							click the button below.
						</p>
						<hr />
						<Button onClick={resetForm} variant="outline-success">
							Send another email
						</Button>
					</Alert>
					:
					<Form onSubmit={handleSubmit}>
						<Row>
							<Col>
								<Form.Control placeholder="Name" onChange={handleName} value={name}/>
							</Col>
							<Col>
								<Form.Control placeholder="Email" onChange={handleEmail} value={email}/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Control as="textarea" rows={3} onChange={handleChange} style={styles.spacing}
									placeholder="Your message here"
									required
									value={message}/>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button variant="primary" type="submit" style={{backgroundColor: "#052F5F", color: "#D5C67A", marginTop: "5vh"}}>
									Submit
								</Button>
							</Col>
						</Row>
					</Form>
				}
			</div>
    )
}

export default CustForm