import React from "react"
import styled from "styled-components"
import PortfolioItem from "./PortfolioItem"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const Header = styled.h1`
	height: 10vh;
`

const CContainer = styled(Container)`
	height: 100vh;
	background-color: #005377;
	padding-top: 14vh;
	color: #F1A208;

	@media (max-width: 768px) {
		height: 200vh;
		padding-top: 10vh;
  }
`

const Projects = {
	justiceserved : {
		name: "Just Ice Served",
		desc: "The adventure of a snowball-wielding vigilante. Made with Unity, playable with only 2 buttons!",
		hasDemo: true,
		link: "https://dion260.itch.io/just-ice-served",
		github: "https://github.com/crown523/just-ice-served"
	},
	zande : {
		name: "Zoom and Enhance",
		desc: "An online solution for faster, more efficient video note-taking. Hack@Home Finalist in Best Web-based Hack and Best Professional Hack.",
		hasDemo: true,
		link: "https://zoomandenhance.herokuapp.com/",
		github: "https://github.com/crown523/zoom_and_enhance"
	},
	shitler : {
		name: "Secret Hitler Discord Bot",
		desc: "A Discord Bot that allows you to play Secret Hitler with your friends!",
		hasDemo: false,
		github: "https://github.com/crown523/sec_hitlerBot"
	},
	autoscribe : {
		name: "AutoScribe",
		desc: "Automatic note taking for conference calls with built-in voice commands.",
		hasDemo: true,
		link: "https://autoscribegt.azurewebsites.net/",
		github: "https://github.com/emilygtan/AutoScribe"
	},
	mastermind: {
		name: "Mastermind Play and Solve",
		desc: "Play a digital version of the Mastermind Board Game, or use the code-cracking algorithm to trick your friends into thinking you're a genius!",
		hasDemo: false,
		github: "https://github.com/crown523/MastermindPlayAndSolve"
	}
}

const Portfolio = () => {
    return (
			<CContainer fluid id="portfolio"> 
				<Row>
					<Col className="text-center">
						<Header> my work </Header>
					</Col>
				</Row>
				<Row className="justify-content-center" >
					<Col xs="auto" md="4">
						<PortfolioItem hasDemo={Projects.justiceserved.hasDemo} projName={Projects.justiceserved.name} projLink={Projects.justiceserved.link}
						projDesc={Projects.justiceserved.desc} githubLink={Projects.justiceserved.github}></PortfolioItem>
					</Col>
					<Col xs="auto" md="4">
						<PortfolioItem hasDemo={Projects.zande.hasDemo} projName={Projects.zande.name} projLink={Projects.zande.link}
						projDesc={Projects.zande.desc} githubLink={Projects.zande.github}></PortfolioItem>
					</Col>
					<Col xs="auto" md="4">
						<PortfolioItem hasDemo={Projects.shitler.hasDemo} projName={Projects.shitler.name}
						projDesc={Projects.shitler.desc}
						githubLink={Projects.shitler.github}></PortfolioItem>
					</Col>
				</Row>
				<Row xs={1} className="justify-content-center" >
					<Col xs="auto" md="6">
						<PortfolioItem hasDemo={Projects.autoscribe.hasDemo} projName={Projects.autoscribe.name} projLink={Projects.autoscribe.link} 
						projDesc={Projects.autoscribe.desc} githubLink={Projects.autoscribe.github}></PortfolioItem>
					</Col>
					<Col xs="auto" md="6">
						<PortfolioItem hasDemo={Projects.mastermind.hasDemo} projName={Projects.mastermind.name}
						projDesc={Projects.mastermind.desc} githubLink={Projects.mastermind.github}></PortfolioItem>
					</Col>
				</Row>
			</CContainer>	
    )
}

export default Portfolio